<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Instalação/Projecto - Comentários</h1>
    <p><b>Instalação/Projeto: </b>{{ projectName }}</p>
    <p><b>Descrição: </b>{{ projectDescription }}</p>
    <PrimeVueTimeline
      :value="commentsList"
      align="alternate"
      class="customized-timeline"
    >
      <template #marker>
        <span class="custom-marker p-shadow-4">
          <i :class="'pi pi-comment'"></i>
        </span>
      </template>
      <template #content="slotProps">
        <Card class="p-shadow-4">
          <template #title>
            {{ slotProps.item.created_by }}
          </template>
          <template #subtitle>
            {{ slotProps.item.created_at }}
          </template>
          <template #content>
            <p>
              {{ slotProps.item.comment }}
            </p>
          </template>
        </Card>
      </template>
    </PrimeVueTimeline>
    <div class="p-mt-4" style="text-align: center">
      <Textarea
        ref="newComment"
        v-model="newComment"
        placeholder="Novo Comentário"
        class="p-col-6"
        :autoResize="true"
        rows="10"
      />
      <br />
      <small v-if="newCommentError" class="p-error" role="alert">
        Comentário está vazio
      </small>
    </div>
    <div class="p-mt-2" style="text-align: center">
      <Button label="Adicionar Comentário" @click="addNewComment" />
    </div>
  </div>
</template>

<script>
import installationservice from "../services/installations.service";
import PrimeVueTimeline from "primevue/timeline";

export default {
  name: "ProjectCommentsView",
  components: {
    PrimeVueTimeline: PrimeVueTimeline,
  },
  data() {
    return {
      loading: true,
      commentsList: [],
      projectName: "",
      projectDescription: "",
      newComment: "",
      newCommentError: false,
    };
  },
  created() {
    this.loading = true;
    this.getComments();
  },
  methods: {
    getComments() {
      this.loading = true;
      installationservice
        .getInstallationComments(this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.commentsList = response.comments;
          this.projectName = response.project;
          this.projectDescription = response.projectDescription;
        });
    },
    addNewComment() {
      if (this.newComment == "") {
        this.newCommentError = true;
        return;
      }
      this.loading = true;
      let bodyParms = {
        comment: this.newComment,
      };
      installationservice
        .addComments(this.$route.params.id, bodyParms)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o comentário",
              life: 3000,
            });
          }
          this.newCommentError = false;
          this.newComment = "";
          this.commentsList.push(response);

          return this.$toast.add({
            severity: "success",
            summary: "Comentário adicionado",
            detail: "Comentário adicionado com sucesso",
            life: 3000,
          });
        });
    },
  },
};
</script>
<style scoped>
.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}
</style>
